import { CHILDMODE_OFF, CHILDMODE_ON } from "../actions/types";

const updateChildmode = (state, action) => {
  if (state === undefined) {
    return {
      status: false,
      child_id: undefined,
      loggedUserState: undefined,
    };
  }

  switch (action.type) {
    case CHILDMODE_ON:
      return {
        status: true,
        child_id: action.child_id,
        loggedUserState: action.payload,
      };

    case CHILDMODE_OFF:
      return {
        status: false,
        child_id: undefined,
        loggedUserState: undefined,
      };

    default:
      return state;
  }
};

export default updateChildmode;
