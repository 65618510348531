import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./ui-kit/error/boundary";
import WebsocketService from "../services/WebsocketService";
import WebsocketContext from "../context/websocket-context";
import store from "../store";
import LayoutSelector from "./layout-selector";
import { QueryClient, QueryClientProvider } from "react-query";

const websocketService = new WebsocketService();
const queryClient = new QueryClient();

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <BrowserRouter>
          <WebsocketContext.Provider value={websocketService}>
            <QueryClientProvider client={queryClient}>
              <LayoutSelector />
            </QueryClientProvider>
          </WebsocketContext.Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
