import {
  FETCH_CONTENT_ERROR,
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_SUCCESS,
} from "../actions/types";

const updateContent = (state, action) => {
  if (state === undefined) {
    return {
      loading: false,
      error: null,
      data: null,
    };
  }

  switch (action.type) {
    case FETCH_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };

    case FETCH_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
      };

    default:
      return state;
  }
};

export default updateContent;
