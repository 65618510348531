import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import SuspenseFallback from "./ui-kit/suspense-fallback";
import NotificationContext from "../context/notification-context";

import ReactNotification, { store as notificationsStore } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const AppMain = lazy(() => import("./app-main"));
const AppGuest = lazy(() => import("./app-guest"));

const LayoutSelector = () => {
  const { isLoggedIn } = useSelector((state) => state.user);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <ReactNotification />
      <NotificationContext.Provider value={notificationsStore}>
        {isLoggedIn ? <AppMain /> : <AppGuest />}
      </NotificationContext.Provider>
    </Suspense>
  );
};

export default LayoutSelector;
