import { MESSAGES_UPDATE_COUNTER } from "../actions/types";

const updateMessages = (state, action) => {
  if (state === undefined) {
    return {
      counter: 0,
    };
  }

  switch (action.type) {
    case MESSAGES_UPDATE_COUNTER:
      return {
        counter: action.payload,
      };

    default:
      return state;
  }
};

export default updateMessages;
