import store from "../store";
import io from "socket.io-client";

const wsConfig = {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  timeout: 20000,
  autoConnect: true,
};

let state = store.getState();
const getToken = (state) => {
  return state.user.token;
};
let currentToken = getToken(store.getState());

const wsHost = `${window.WS_HOST}:${window.WS_PORT}`;

class WebsocketService {
  subscribedChannels = [];
  socket = null;

  constructor() {
    this.connect(currentToken);

    this.loadState();
    this.currentState = state;

    store.subscribe(() => {
      // console.log('constructor');
      const prevToken = currentToken;
      currentToken = getToken(store.getState());

      if (prevToken !== currentToken || !this.socket) {
        state = store.getState();

        this.socket = null;
        this.connect(currentToken);

        this.loadState();
      }
    });

    // iOs hack: reconnect on window focus
    window.addEventListener("focus", () => {
      if (!this.socket) this.connect(currentToken);
    });
  }

  connect = (token) => {
    this.socket = io(wsHost, {
      ...wsConfig,
      query: `auth_token=${token}`,
    });
  }

  reset = () => {
    console.log("reset");
    this.socket = null;
  };

  loadState = () => {
    if (!this.socket) return;

    this.socket.on("reconnect", function () {
      // do not rejoin from here, since the socket.id token and/or rooms are still
      // not available.
      console.log("Reconnecting");
    });

    this.socket.on("disconnect", (reason) => {
      console.log("disconnect", reason);
      this.socket = null;
    });

    this.socket.on("unauthorized", () => {
      console.log("unauth");
      this.reset();
    });
  };

  listen(channel, fn) {
    if (!this.socket) {
      console.log("no websocket");
      return;
    }
    this.socket.on(channel, fn);
  }

  stopListening(channel, fn) {
    if (!this.socket) {
      console.log("no websocket");
      return;
    }
    this.socket.removeListener(channel, fn);
  }

  sendMessage(emitter, data = undefined) {
    if (!this.socket) {
      console.log("no websocket");
      return;
    }
    this.socket.emit(emitter, data);
  }
}

export default WebsocketService;
