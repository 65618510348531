import {
  NOTIFICATIONS_CLEAR,
  NOTIFICATIONS_FILL,
  NOTIFICATIONS_NEW,
  NOTIFICATIONS_READ_ALL,
  NOTIFICATIONS_RESET_TOTAL,
  NOTIFICATIONS_SECTION_DATES,
  NOTIFICATIONS_SECTION_MAIN,
  NOTIFICATIONS_UPDATE,
} from "../actions/types";

const updateNotification = (state, action) => {
  if (state === undefined) {
    return {
      [NOTIFICATIONS_SECTION_MAIN]: [],
      [NOTIFICATIONS_SECTION_DATES]: [],
    };
  }

  switch (action.type) {
    case NOTIFICATIONS_NEW:
      return {
        ...state,
        [action.payload.section]: [
          action.payload.data,
          ...state[action.payload.section].filter(
            (n) => n.key !== action.payload.data.key
          ),
        ],
      };

    case NOTIFICATIONS_FILL:
      return {
        ...state,
        [action.payload.section]: action.payload.data,
      };

    case NOTIFICATIONS_CLEAR:
      return {
        ...state,
        [action.payload.section]: [],
      };

    case NOTIFICATIONS_READ_ALL:
      return {
        ...state,
        [action.payload.section]: [
          ...state[action.payload.section].map((n) => ({ ...n, status: "1" })),
        ],
      };

    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        [action.payload.section]: [
          ...state[action.payload.section].map((n) => {
            const newObject = action.payload.data.filter(
              (o) => o.key === n.key
            );
            if (!newObject.length) return n;
            return { ...newObject[0], data: JSON.parse(newObject[0].data) };
          }),
        ],
      };

    case NOTIFICATIONS_RESET_TOTAL:
      return {
        [NOTIFICATIONS_SECTION_MAIN]: [],
        [NOTIFICATIONS_SECTION_DATES]: [],
      };

    default:
      return state;
  }
};

export default updateNotification;
