import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import StateLoader from "./services/StateLoader";
import reducers from "./reducers";
import thunk from 'redux-thunk';

/**
 * Redux state to local storage
 * @type {StateLoader}
 */
const stateLoader = new StateLoader();

//const store = createStore(reducers, stateLoader.loadState());
const createStoreWithMiddleware = compose(
    applyMiddleware(thunk)
)(createStore);

const store = createStoreWithMiddleware(combineReducers({
    // your reducers here
    ...reducers
}), stateLoader.loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// subscribe to user data changes
// save it it was changed
export function selectUserData(state) {
    return state.user;
}

const selectChildmodeData = state => state.childmode;

let currentValue = selectUserData(store.getState());
let currentChildmodeValue = selectChildmodeData(store.getState());

store.subscribe(() => {
    const previousValue = currentValue;
    currentValue = selectUserData(store.getState());

    const prevChildmodeValue = currentChildmodeValue;
    currentChildmodeValue = selectChildmodeData(store.getState());

    if (previousValue !== currentValue || prevChildmodeValue !== currentChildmodeValue) {
        stateLoader.saveState({
            user: currentValue,
            childmode: currentChildmodeValue,
        });
    }
});

export default store;