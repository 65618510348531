import {
  FETCH_EVENTS_CALENDAR_FAILURE,
  FETCH_EVENTS_CALENDAR_REQUEST,
  FETCH_EVENTS_CALENDAR_SUCCESS,
} from "../actions/types";

const updateEventsCalendar = (state, action) => {
  if (state === undefined) {
    return {
      loading: false,
      error: null,
      data: null,
      month: null,
    };
  }

  switch (action.type) {
    case FETCH_EVENTS_CALENDAR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case FETCH_EVENTS_CALENDAR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };

    case FETCH_EVENTS_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
      };

    default:
      return state;
  }
};

export default updateEventsCalendar;
