export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

export const FETCH_LOGOUT_SUCCESS = "FETCH_LOGOUT_SUCCESS";
export const FETCH_LOGOUT_ERROR = "FETCH_LOGOUT_ERROR";

export const FETCH_CHANGE_PROFILE_REQUESTED = "FETCH_CHANGE_PROFILE_REQUESTED";
export const FETCH_CHANGE_PROFILE_SUCCESS = "FETCH_CHANGE_PROFILE_SUCCESS";
export const FETCH_CHANGE_PROFILE_ERROR = "FETCH_CHANGE_PROFILE_ERROR";

export const FETCH_REGISTER_REQUEST = "FETCH_REGISTER_REQUEST";
export const FETCH_REGISTER_SUCCESS = "FETCH_REGISTER_SUCCESS";
export const FETCH_REGISTER_FAILURE = "FETCH_REGISTER_FAILURE";

export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_REQUESTED = "FETCH_TOKEN_REQUESTED";
export const FETCH_TOKEN_ERROR = "FETCH_TOKEN_ERROR";

export const FAMILY_EDIT = "FAMILY_EDIT";
export const FAMILY_DELETE = "FAMILY_DELETE";
export const FAMILY_ADD = "FAMILY_ADD";

export const NOTIFICATIONS_NEW = "NOTIFICATIONS_NEW";
export const NOTIFICATIONS_FILL = "NOTIFICATIONS_FILL";
export const NOTIFICATIONS_CLEAR = "NOTIFICATIONS_CLEAR";
export const NOTIFICATIONS_READ_ALL = "NOTIFICATIONS_READ_ALL";
export const NOTIFICATIONS_UPDATE = "NOTIFICATIONS_UPDATE";
export const NOTIFICATIONS_RESET_TOTAL = "NOTIFICATIONS_RESET_TOTAL";

export const NOTIFICATIONS_SECTION_MAIN = "main";
export const NOTIFICATIONS_SECTION_DATES = "dates";

export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_ERROR = "FETCH_CONTENT_ERROR";
export const FETCH_CONTENT_REQUEST = "FETCH_CONTENT_REQUEST";

export const FETCH_EVENTS_CALENDAR_REQUEST = "FETCH_EVENTS_CALENDAR_REQUEST";
export const FETCH_EVENTS_CALENDAR_SUCCESS = "FETCH_EVENTS_CALENDAR_SUCCESS";
export const FETCH_EVENTS_CALENDAR_FAILURE = "FETCH_EVENTS_CALENDAR_FAILURE";

export const CHILDMODE_ON = "CHILDMODE_ON";
export const CHILDMODE_OFF = "CHILDMODE_OFF";

export const MESSAGES_UPDATE_COUNTER = "MESSAGES_UPDATE_COUNTER";
