import * as types from "../actions/types";

const updateUser = (state, action) => {
  if (state === undefined) {
    return {
      loading: false,
      error: null,
      token: null,
      refreshToken: null,
      data: null,
      isLoggedIn: false,
      websocketToken: null,
    };
  }

  switch (action.type) {
    case types.FETCH_LOGIN_REQUEST:
      return {
        loading: true,
        error: null,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
        websocketToken: null,
      };

    case types.FETCH_LOGIN_SUCCESS:
      return {
        loading: false,
        error: null,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
        data: action.payload.user,
        isLoggedIn: true,
      };

    case types.FETCH_LOGIN_FAILURE:
      return {
        loading: false,
        error: action.payload,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
        websocketToken: null,
      };

    case types.FETCH_REGISTER_REQUEST:
      return {
        loading: true,
        error: null,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
        websocketToken: null,
      };

    case types.FETCH_REGISTER_SUCCESS:
      return {
        loading: false,
        error: null,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
        data: action.payload.user,
        isLoggedIn: true,
      };

    case types.FETCH_REGISTER_FAILURE:
      return {
        loading: false,
        error: action.payload,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
        websocketToken: null,
      };

    case types.FETCH_TOKEN_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        token: action.payload.token,
        isLoggedIn: true,
      };

    case types.FETCH_TOKEN_ERROR:
      return {
        loading: false,
        error: action.payload,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
      };

    case types.FETCH_LOGOUT_SUCCESS:
      return {
        loading: false,
        error: null,
        token: null,
        refreshToken: null,
        data: null,
        isLoggedIn: false,
        websocketToken: null,
      };

    case types.FETCH_LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.FETCH_CHANGE_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.FETCH_CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case types.FETCH_CHANGE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.FAMILY_EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          families: state.data.families.map((family) => {
            if (family.id === action.payload.id)
              return { ...family, ...action.payload };
            return family;
          }),
        },
      };

    case types.FAMILY_DELETE:
      return {
        ...state,
        data: {
          ...state.data,
          families: state.data.families.filter(
            (family) => family.id !== action.payload.id
          ),
        },
      };

    case types.FAMILY_ADD:
      return {
        ...state,
        data: {
          ...state.data,
          families: [...state.data.families, action.payload],
        },
      };

    default:
      return state;
  }
};

export default updateUser;
