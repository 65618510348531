import updateUser from "./user";
import updateEventsCalendar from "./events-calendar";
import updateContent from "./content";
import updateChildmode from "./childmode";
import updateNotification from "./notification";
import updateMessages from "./chat";

const reducers = {
  user: updateUser,
  eventsCalendar: updateEventsCalendar,
  content: updateContent,
  childmode: updateChildmode,
  notifications: updateNotification,
  messages: updateMessages,
};

export default reducers;
