/**
 * Serving Redux store to localStorage
 */
export default class StateLoader {
  storageKey = "newstars:state";

  loadState() {
    try {
      let serializedState = localStorage.getItem(this.storageKey);

      if (serializedState === null) return this.initializeState();

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    try {
      let serializedState = JSON.stringify(state);
      localStorage.setItem(this.storageKey, serializedState);
    } catch (err) {
      console.log(err);
    }
  }

  initializeState() {
    // default state here
  }
}
